<div class="d-flex flex-column gap-3">
    <div class="alert alert-info m-0">
        You can choose one of the following options while scheduling a meeting:
        <ul class="mb-0">
            <li>Click <b>Let me create my own agenda</b> to continue with an empty agenda, which you can fill out in the
                next step.
            </li>
            <li>Or choose from a team or organisation template below.
                <ul>
                    <li>If you want to get started straight away, you can click <b>Run meeting now</b> after selecting
                        a template.
                    </li>
                    <li>Or you can customise the agenda as needed by clicking <b>Customise Agenda</b></li>
                </ul>
            </li>
        </ul>
    </div>

    <div class="d-flex flex-row flex-wrap gap-2">
        <button class="btn btn-primary"
                adaptButtonIcon="edit"
                (click)="createOwnAgenda()"
                data-test="create-own-agenda-button">Let me create my own agenda
        </button>
    </div>

    <hr class="m-0" />

    <ng-container *adaptLoading="loading">
        @if (teamAgendaTemplates.length > 0) {
            <div>
                <h3>{{teamAgendaTemplates[0].template.team?.name ?? "Unknown"}} Team templates</h3>
                <div class="d-flex flex-row flex-wrap gap-2">
                    @for (agenda of teamAgendaTemplates; track agenda.template.meetingAgendaTemplateId) {
                        <ng-container *ngTemplateOutlet="agendaButton; context: { agenda }"></ng-container>
                    }
                </div>
            </div>
        }

        @if (globalTemplates.length > 0) {
            <div>
                <h3>Organisation templates</h3>
                <div class="d-flex flex-row flex-wrap gap-2">
                    @for (agenda of globalTemplates; track agenda.template.meetingAgendaTemplateId) {
                        <ng-container *ngTemplateOutlet="agendaButton; context: { agenda }"></ng-container>
                    }
                </div>
            </div>
        }

        @if (otherTeamAgendaTemplates.size > 0) {
            <div>
                <h3>Meeting templates from other teams
                    <button [(adaptCollapsibleButton)]="otherTeamsCollapsed"></button>
                </h3>
                <div [collapse]="otherTeamsCollapsed"
                     [isAnimated]="false"
                     class="alert alert-light p-2 mb-0">
                    <div class="d-flex flex-column gap-2">
                        @for (item of otherTeamAgendaTemplates | keyvalue; track item.key.teamId) {
                            <div>
                                <h3>{{item.key.name}} team</h3>
                                <div class="d-flex flex-row flex-wrap gap-2">
                                    @for (agenda of item.value; track agenda.template.meetingAgendaTemplateId) {
                                        <ng-container *ngTemplateOutlet="agendaButton; context: { agenda }"></ng-container>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }

        @if (pathwayTemplates.length > 0) {
            <div>
                <h3>Pathway meeting templates
                    <button [(adaptCollapsibleButton)]="pathwaysCollapsed"></button>
                </h3>
                <div [collapse]="pathwaysCollapsed"
                     [isAnimated]="false"
                     class="alert alert-light p-2 mb-0">
                    <div class="d-flex flex-row flex-wrap gap-2">
                        @for (agenda of pathwayTemplates; track agenda.template.meetingAgendaTemplateId) {
                            <ng-container *ngTemplateOutlet="agendaButton; context: { agenda }"></ng-container>
                        }
                    </div>
                </div>
            </div>
        }

        @if (selectedAgendaTemplate && !agendaHasItems) {
            <div class="alert alert-warning m-0">The selected meeting agenda has no agenda items. Please select a
                different agenda.
            </div>
        }
    </ng-container>
</div>

<ng-template #agendaButton
             let-agenda="agenda">
    <div class="btn agenda-btn"
         [class.is-selected]="selectedAgendaTemplate === agenda"
         [class.is-ad-hoc]="agenda.type === MeetingAgendaType.AdHoc"
         [class.is-system]="agenda.type === MeetingAgendaType.System"
         [class.is-org]="agenda.type === MeetingAgendaType.Organisation"
         [class.is-team]="agenda.type === MeetingAgendaType.Team"
         (click)="onAgendaSelected(agenda)">
        <adapt-display-agenda-name [meetingAgendaTemplate]="agenda.template"></adapt-display-agenda-name>
    </div>
</ng-template>

<ng-template #createAgendaButton>
    <button *adaptIfAuthorised="ConfigureTeam;Entity:runData.meeting.team"
            type="button"
            class="p-1"
            adaptButton="addLink"
            data-test="create-agenda-button"
            [disabled]="submitting"
            [adaptBlockingClick]="createNewAgenda">Create new template
    </button>
</ng-template>

<ng-template #startMeetingNowButton>
    <div [adaptTooltip]="startMeetingNowTooltip"
         [class.d-none]="!showRunMeetingNowButton">
        <button type="button"
                class="btn btn-secondary"
                adaptButtonIcon="runMeetingNow"
                data-test="run-meeting-now-button"
                [disabled]="!isValid.value || !!nonStartableMeetingInfo || !this.selectedAgendaTemplate"
                [adaptBlockingClick]="populateAndRunMeetingNow">{{runMeetingNowButtonText}}</button>
    </div>
</ng-template>
